.Order {
    margin-top: 10px;
}

.Order .cards {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
}

.Order .Card {
    margin: 10px;
}

.Order p {
    border: 3px solid purple;
    width: 68px;
    margin: 20px auto;
    padding: 15px 20px;
}

.Order p:hover {
    cursor: pointer;
    background-color: purple;
    color: #FB8B24;
    font-weight: bold;
}

.Order .form {
    text-align: center;
    margin: 10px;
}