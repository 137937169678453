.Card {
    width: 200px;
    height: 200px;
    border: 5px solid black;
    text-align: center;
    margin: 10px;
}

.Card:hover {
    color: #FB8B24;
    border: 5px solid #FB8B24;
    cursor: pointer;
}

.active {
    border: 5px solid #FB8B24;
    color: #FB8B24;
}

.Card img {
    width: 150px;
    height: 65px;
}