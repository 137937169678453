.Home {
    width: 50%;
    margin: 100px auto;
    text-align: center;
}

.Home h1 {
    color: purple;
}

.Home button {
    border: 3px solid purple;
    width: 100px;
    margin: 20px auto;
    padding: 15px 20px;
    background-color: #fff;
}

.Home button:hover {
    cursor: pointer;
    background-color: purple;
    color: #FB8B24;
    font-weight: bold;
}

.Home img {
    align-content: center;
    width: 175px;
    height: 165px;
    margin: 15px;
}

.rotate {
    animation: rotation 15s infinite linear;
}
  
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }